import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import loadable from '../utils/loadable';

const Main = loadable(() => import('../pages'));
const Login = loadable(() => import('../pages/login'));
// const MobileIndex = loadable(() => import('../mobile/MobileIndex'));

const PrivateRoute = (path, component) => {
	if(sessionStorage.getItem("accessToken")){
		return <Route path={path} component={Main} />
	}
	else {
		return <Redirect to="/" />;
	}
}
export default class MainRouter extends Component {
	render() {
		return (
			<HashRouter>
				<Switch>
				    {/* <Route path="/mobile" component={MobileIndex} /> */}
				    {/* <Route path="/main" component={Main} /> */}
					<PrivateRoute path="/main" component={Main} />
				    <Route path="/login" component={Login} />
				    <Route path="/" component={Login} />
				</Switch>
		    </HashRouter>
		)
	}
}