import React, {Fragment} from 'react';
import Index from './routers/mainRouter'; 
// import { isMobile } from './utils/common';

export default class App extends React.Component {
  constructor(props) {
  	super(props);
    // if(!isMobile) {
  	//   this._loadScript(['public', 'md5', 'mp4remux', 'videoMediaSource', 'WebsocketServer', 'Sylvester', 'WebGLCanvas', 'audioPlayer', 'workerManager', 'streamDrawer', 'playerControl', 'ivs'])  
    // }
  }
  _loadScript(scripts) {
  	for (var i = 0; i < scripts.length; i++) {
  		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = 'static/'+scripts[i]+'.js';
		document.head.appendChild(script); 
  	};
  }
  render() {
    return (
        <Fragment> 
          <Index />
        </Fragment>
    )
  }
}
